import React from "react";


const Footer = () => {
    return (
        <div className="footer--section">
            <div className="vertical__line"></div>
            <div className="footer__text1">2022년 9월 17일 오후 5시</div>
            <div className="footer__text2">두 사람의 아름다운 여정을 함께 축복해 주시면 감사하겠습니다</div>
        </div>
    );
}

export default Footer;

