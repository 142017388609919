import React from "react";
// import mainImg from "../assets/main__image.jpg";

const MainImage = () => {
    return (
        <div className="main__image-border">
            <div className="main__image-wrap"></div>
        </div>
    );
}

export default MainImage;