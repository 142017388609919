import React from "react";
import Countdown from "./Countdown";


const Invitation = () => {
    return (
        <div className="wedding__invitation-wrap">
            <div className="wedding__invitation">Wedding Ceremony Invitation</div>
            <div className="wedding__invitation-date">09.17</div>
            <div className="wedding__invitation-countdown">
                <Countdown />
            </div>
        </div>
    );
}

export default Invitation;






