import React, { useEffect, useState } from "react";

const useCountdown = (deadline: Date) => {
    // Time is in seconds
    const [time, setTime] = useState(
        Math.max(0, Math.floor((deadline.getTime() - Date.now()) / 1000))
    );

    const decrement = () =>
        setTime((prevTime) => {
            return prevTime === 0 ? 0 : prevTime - 1;
        });

    useEffect(() => {
        const id = setInterval(decrement, 1000);
        return () => clearInterval(id);
    }, []);

    const format = (num: number): string => {
        return num < 10 ? '0' + num : num.toString();
    };

    return {
        days: format(Math.floor(time / (3600 * 24))),
        hours: format(Math.floor((time / 3600) % 24)),
        minutes: format(Math.floor((time / 60) % 60)),
        seconds: format(time % 60),
    };
};

const Countdown = () => {
    const [deadline] = useState(new Date("2022-09-17T17:00:00+0900"))
    const time = useCountdown(deadline);

    return (
        <div className="countdown__wrap">
            {/* <div className="location__name">마리나파크 웨딩홀</div>
            <div className="location__address">서울특별시 서초구 올림픽대로 2085-18 서래나루 2층 마리나파크웨딩홀</div> */}
            <div className="countdown">{time.days}days {time.hours}hours {time.minutes}minutes {time.seconds}seconds</div>
        </div>
    );
}

export default Countdown;