import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainPage from "./components/MainPage";

const App = props => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<MainPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
