import React, { useState, useCallback } from "react";
import { PhotoAlbum as MyPhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import image1 from "../assets/images/image1.jpg"
import image2 from "../assets/images/image2.jpg"
import image3 from "../assets/images/image3.jpg"
import image4 from "../assets/images/image4.jpg"
import image5 from "../assets/images/image5.jpg"
import image6 from "../assets/images/image6.jpg"
import image7 from "../assets/images/image7.jpg"
import image8 from "../assets/images/image8.jpg"
import image9 from "../assets/images/image9.jpg"
import image10 from "../assets/images/image10.jpg"


const PhotoAlbum = () => {
    const [index, setIndex] = useState(-1);

    const photos = [
        {
            src: image8,
            width: 3648,
            height: 5472
        },
        {
            src: image1,
            width: 4208,
            height: 2800
        },
        {
            src: image2,
            width: 4092,
            height: 2723
        },
        {
            src: image3,
            width: 4256,
            height: 2832
        },
        {
            src: image10,
            width: 3385,
            height: 5077
        },
        {
            src: image4,
            width: 4256,
            height: 2832
        },
        {
            src: image5,
            width: 5475,
            height: 3650
        },
        {
            src: image7,
            width: 4846,
            height: 3231
        },
        {
            src: image6,
            width: 5446,
            height: 3631
        },
        {
            src: image9,
            width: 5952,
            height: 3968
        },
    ];


    return (
        <div className="photo__album--section">
            <div className="section__title">gallery</div>
            <MyPhotoAlbum layout="masonry" photos={photos} columns={3} onClick={(event, photo, index) => setIndex(index)} />
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                plugins={[Thumbnails]}
            />
        </div>
    );
}

export default PhotoAlbum;