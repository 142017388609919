import React, { useState } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";


const KakaoMap = () => {
    const [level, setLevel] = useState(4);

    return (
        <div className="map--section">
            <div className="section__title">location</div>
            <div className="location__name">마리나파크 웨딩홀</div>
            <div className="location__address">서울특별시 서초구 올림픽대로 2085-18</div>
            < Map
                center={{ lat: 37.5098106360936, lng: 126.99312890643898 }
                }
                className="kakao__map"
                style={{ width: "70vw", height: "500px" }}
                level={level}
            >
                <MapMarker position={{ lat: 37.5098106360936, lng: 126.99312890643898 }} />
            </Map >
            <div className="nav__location">
                <div className="nav__car">
                    <div className="nav__title">주차안내</div>
                    <div className="nav__title--text">서래나루 주차장 이용</div>
                </div>
                <div className="nav__sub">
                    <div className="nav__title">지하철 및 셔틀버스안내</div>
                    <div className="nav__title--text">3, 7, 9호선 고속버스터미널역 6번출구 앞 출발 (10 - 15분 간격)</div>
                </div>
                <div className="nav__bus">
                    <div className="nav__title">버스안내</div>
                    <div className="nav__title--text1">반포한강공원 세빛섬 정류장: 405, 740</div>
                    <div className="nav__title--text">반포대교남단 한강시민공원입구 정류장: 143, 401, 406</div>
                </div>
            </div>
        </div >
    );
}

export default KakaoMap;


