import React from "react";


const Textbox = () => {
    return (
        <div className="textbox--section">
            <div className="section__title">invitation</div>
            <div className="textbox__wrap">
                <div className="textbox__text">하나님이 맺어주신 인연의 소중함을 깊이 새기고, 사랑하는 가족 앞에서 신의를 다짐하며 저희들 인생의 길을 함께 걸어가려 합니다. 기적과 같은 오늘 함께 하시어 축복해 주시면 더 없는 기쁨으로 간직하겠습니다.</div>
            </div>
            <div className="vertical__line"></div>
            <div className="textbox__groomside">유덕상<span>・</span>오민정<span>의 장남</span> 유병찬</div>
            <div className="textbox__brideside">이상철<span>・</span>고유덕<span>의 장녀</span> 이기쁨</div>
        </div>
    );
}

export default Textbox;


