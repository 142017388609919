import React from "react";
import weddingLogo from "../assets/images/wedding_logo.png";

const MainName = () => {
    return (
        <div className="MainName__wrap">
            <img src={weddingLogo} alt="wedding-logo" className="wedding__logo" />
            <div className="MainName">
                <div className="MainName__groom">
                    <div className="MainName__groom-1">유</div>
                    <div className="MainName__groom-2">병</div>
                    <div className="MainName__groom-3">찬</div>
                </div>
                <div className="MainName__bride">
                    <div className="MainName__bride-1">이</div>
                    <div className="MainName__bride-2">기</div>
                    <div className="MainName__bride-3">쁨</div>
                </div>
            </div>
        </div>
    );
}

export default MainName;