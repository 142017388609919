import React from "react";
import Invitation from "./Invitation";
import MainName from "./MainName";
import MainImage from "./MainImage";
import Textbox from "./Textbox";
import KakaoMap from "./KakaoMap";
import PhotoAlbum from "./PhotoAlbum";
import Footer from "./Footer";


const MainPage = () => {
    return (
        <div className="mainpage">
            <Invitation />
            <MainName />
            <MainImage />
            <Textbox />
            <PhotoAlbum />
            <KakaoMap />
            <Footer />
        </div>
    );
}

export default MainPage;